import React, { useEffect, useState } from "react";
import { deleteUser, getUsers } from "../../../api/usersApi";
import Table from "../../Table/Table";
import Input from "../../Layout/Input";
import styles from "../Users.module.scss";
import Spinner from "../../Layout/Loading/Spinner";
import ImageAndName from "./ImageAndName";
import { ReactComponent as LinkIcon } from "../../../assets/images/openLink.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete.svg";
import DeletePrompt from "../../DeletePrompt/DeletePrompt";
import { Link } from "react-router-dom";
import formatDate from "../../../utils/formatDate";

const Applicants = () => {
   const [search, setsearch] = useState("");
   const [applicants, setapplicants] = useState([]);
   const [currentPage, setcurrentPage] = useState(1);
   const [totalPages, settotalPages] = useState(null);
   const [loading, setloading] = useState(true);
   const [deleteModal, setdeleteModal] = useState(false);
   const [deleteApplicantId, setdeleteApplicantId] = useState(null);

   const [columns] = useState([
      {
         content: "Name",
         minWidth: "300px"
      },
      {
         content: "hergestellt in",
         minWidth: "200px"
      },
      {
         content: "Optionen",
         minWidth: "150px"
      }
   ]);

   const getUsersWrapper = () => {
      setloading(true);

      getUsers("Applicant", currentPage, 10, search).then((res) => {
         setcurrentPage(res.CurrentPage);
         settotalPages(res.TotalPages);
         const rows = res.list.map((item) => ({
            metaData: {
               id: item.Id,
               firstName: item.FirstName,
               lastName: item.LastName,
               path: `/applicant/${item.Id}`
            },
            rowData: [
               {
                  content: (
                     <ImageAndName
                        name={`${item.FirstName} ${item.LastName}`}
                        url={item.PhotoUrl}
                     />
                  )
               },
               { content: formatDate(item.UserCreatedTime) },
               {
                  content: (
                     <Options url={`/applicant/${item.Id}`} id={item.Id} />
                  )
               }
            ]
         }));
         setapplicants(rows);
         setloading(false);
      });
   };

   const onPageChange = (e, page) => {
      console.log(page);
        setcurrentPage(page);
      };

   useEffect(() => {
      getUsersWrapper();
   }, [search, currentPage]);

   const Options = ({ url, id }) => {
      return (
         <div className="dfc">
            <Link to={url} className="redSvg mx-10">
               <LinkIcon />
            </Link>
            <button
               className="redSvg mx-10"
               onClick={() => {
                  setdeleteModal(true);
                  setdeleteApplicantId(id);
               }}
            >
               <DeleteIcon />
            </button>
         </div>
      );
   };

   return (
      <>
         <Input
            value={search}
            label="Suche"
            onChange={(e) => setsearch(e.target.value)}
            inputContainerClass={styles.searchInput}
         />
         {loading ? (
            <>
               <div className={`${styles.spinnerContainer} dfc `}>
                  <Spinner />
               </div>
            </>
         ) : (
            <>
               <Table
                  columns={columns}
                  rows={applicants}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange = {onPageChange}
                  onNextPage={() => setcurrentPage(currentPage + 1)}
                  onPreviousPage={() => setcurrentPage(currentPage - 1)}
               />
            </>
         )}
         <DeletePrompt
            open={deleteModal}
            onClose={() => {
               setdeleteModal(false);
               setdeleteApplicantId(null);
            }}
            onCancel={() => {
               setdeleteModal(false);
               setdeleteApplicantId(null);
            }}
            onConfirm={() => {
               deleteUser(deleteApplicantId).then((res) => {
                  getUsersWrapper();
                  setdeleteModal(false);
                  setdeleteApplicantId(null);
               });
            }}
          
         />
      </>
   );
};

export default Applicants;
