import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../components/Login/Login";
import PrivateOutlet from "./PrivateOutlet";
import PageLayout from "../components/Layout/PageLayout";
import Users from "../components/Users/Users";
import Settings from "../components/Settings/Settings";
import ApplicantProfile from "../components/Users/Profiles/ApplicantProfile";
import Jobs from "../components/Jobs/Jobs";
import Blogs from "../components/Blogs/Blogs";
import Partner from "../components/Partner/Partner";
import CompanyProfile from "../components/Users/Profiles/CompanyProfile";

const Routing = () => {
   return (
      <>
         <BrowserRouter>
            <Routes>
               <Route path="/login" element={<Login />} />
               <Route path="/" element={<PrivateOutlet />}>
                  <Route element={<PageLayout />}>
                     <Route index element={<Navigate to={"/users"} />} />
                     <Route path="users" element={<Users />} />
                     <Route path="jobs" element={<Jobs />} />
                     <Route path="blogs" element={<Blogs />} />
                     <Route path="partner-und-kooperationen" element={<Partner />} />
                     <Route path="settings" element={<Settings />} />
                     <Route
                        path="applicant/:id"
                        element={<ApplicantProfile />}
                     />
                     <Route path="company/:id" element={<CompanyProfile />} />
                     <Route
                        path="organisation/:id"
                        element={<CompanyProfile organisation={true} />}
                     />
                  </Route>
               </Route>
            </Routes>
         </BrowserRouter>
      </>
   );
};

export default Routing;
