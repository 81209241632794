import React from "react";
import styles from "./Toggle.module.scss";

const Toggle = ({ onChange, value }) => {
   return (
      <div className={styles.toggleContainer}>
         <input type="checkbox" checked={value} onChange={onChange} />
         <span className={styles.span}></span>
      </div>
   );
};

export default Toggle;
