import React, { useEffect, useState } from "react";
import Button from "../Layout/Button";
import Input from "../Layout/Input";
import Modal from "../Layout/Modal";
import Toggle from "../Layout/Toggle";
import styles from "../Jobs/Jobs.module.scss";

const AddModal = ({ open, onClose, onAdd }) => {
   const [data, setData] = useState({
    partnerName: "",
    address: "",
    establishmentType: "",
    mapButton: false
  });
   const [errorMsg, seterrorMsg] = useState("");
   const [addressError, setAddressError] = useState("");
   const [typeError, setTypeError] = useState("");
   const [image, setImage] = useState(null);
   const [disabled, setDisabled] = useState(true);
 
   const onSubmit = (e) => {
      e.preventDefault();

      if (data.partnerName.length === 0) 
         seterrorMsg("Dieses Feld kann nicht leer sein.");
       if (data.address.length === 0) 
            setAddressError("Dieses Feld kann nicht leer sein.");
    if (data.establishmentType.length === 0) {
                setTypeError("Dieses Feld kann nicht leer sein.");
      } else {
         onAdd(data, image);
         setData({
            partnerName: "",
            address: "",
            establishmentType: ""
          });
      }

      setImage(null);

    
   };

   const handleChange = e => setData({...data, [e.target.name]:e.target.value})

   const  onImageChange = event => {
      if (event.target.files && event.target.files[0]) {
        let img = event.target.files[0];
        setImage({
         file: img,
         url:URL.createObjectURL(img)});
      }
    };

    useEffect(() => {
      if(data.partnerName != '' && data.address != '' && data.establishmentType != '' && image != null) {
         setDisabled(false);
      } 
      else {
         setDisabled(true);
      }
    }, [data.partnerName, data.address, data.establishmentType, image ]);
  
    
   return (
      <>
         <Modal
            open={open}
            onClose={() => {
                setData({
                    partnerName: "",
                    address: "",
                    establishmentType: ""
                  });
               seterrorMsg("");
               setAddressError("");
               setTypeError("");
               onClose();
            }}
         >
            <div className={`${styles.modalContainer}`}>
               <form className="w100 dfc flexCol" onSubmit={onSubmit}>
                  <Input
                     value={data.name}
                     name="partnerName"
                     label="Name"
                     onChange={(e) => {
                        seterrorMsg("");
                        handleChange(e)
                     }}
                     inputContainerClass={styles.searchInput}
                     errorMsg={errorMsg}
                  />
                   <Input
                     value={data.establishmentType}
                     name="establishmentType"
                     label="Art der Einrichtung"
                     onChange={(e) => {
                        setTypeError("");
                        handleChange(e)
                     }}
                     inputContainerClass={styles.searchInput}
                     errorMsg={typeError}
                  />
                    <Input
                     value={data.address}
                     name="address"
                     label="Adresse"
                     onChange={(e) => {
                        setAddressError("");
                        handleChange(e)
                     }}
                     inputContainerClass={styles.searchInput}
                     errorMsg={addressError}
                  />
                  <div className={styles.toggleButton}>
                   <Toggle
                  value={data.mapButton}
                  onChange={() => {
                     setData({...data, mapButton: !data.mapButton});
                  }}
                  />
                  <label>Entdecken button</label>
                  </div>

                  <div className={styles.uploadPicture}>
                     <img src={image?.url} />
                     <label for="file-upload">
                           Bild hochladen
                     </label>
                     <input type="file" id="file-upload" onChange={onImageChange} />
                  </div>
                

                  <Button type="submit" text="Hinzufügen" medium disabled={disabled}/>
               </form>
            </div>
         </Modal>
      </>
   );
};

export default AddModal;
