import React, { useEffect, useState } from "react";
import Button from "../../Layout/Button";
import Select from "../../Layout/Select";
import Modal from "../../Layout/Modal";
import styles from "../../Jobs/Jobs.module.scss";
import { getPartner } from "../../../api/partnerApi";


const PartnersModal = ({ open, onClose, onAdd, partner, setPartner }) => {
   const [errorMsg, seterrorMsg] = useState("");
   const [data, setData] = useState([]);

   const onSubmit = (e) => {
      e.preventDefault();

      if (data.partner === 0) 
         seterrorMsg("Dieses Feld kann nicht leer sein.");
     else {
         onAdd(partner);
         setPartner('');
      }
   };

   useEffect(() => { getPartner().then(res => setData(res.map(e => { return {id: e.PartnerId, name:e.PartnerName}})))}, [])

   return (
      <>
         <Modal
            open={open}
            onClose={() => {
              setPartner('');
               seterrorMsg("");
               onClose();
            }}
         >
            <div className={`${styles.modalContainer}`}>
               <form className="w100 dfc flexCol" onSubmit={onSubmit}>
                  <Select
                     value={partner}
                     label="Partner name"
                     onChange={(e) => {
                        seterrorMsg("");
                        setPartner(e.target.value)
                     }}
                     inputContainerClass={styles.searchInput}
                     errorMsg={errorMsg}
                     options={data}
                  />
                                
                  <Button type="submit" text="Hinzufügen" medium />
               </form>
            </div>
         </Modal>
      </>
   );
};

export default PartnersModal;
