import React, { useState } from "react";
import Input from "../Layout/Input";
import Statistics from "../Statistics/Statistics";

import styles from "./Blogs.module.scss";
import BlogsTable from "./BlogsTable";

const Blogs = () => {
   const [search, setsearch] = useState("");

   return (
      <>
         <Statistics />
         <div className={styles.container}>
            <Input
               value={search}
               label="Suche"
               onChange={(e) => setsearch(e.target.value)}
               inputContainerClass={styles.searchInput}
            />
            <BlogsTable search={search} />
         </div>
      </>
   );
};

export default Blogs;
