import React, { useCallback, useEffect, useState } from "react";
import {
   addProfession,
   deleteProfession,
   getProfessions
} from "../../api/jobsApi";
import Button from "../Layout/Button";
import Input from "../Layout/Input";
import Spinner from "../Layout/Loading/Spinner";
import Table from "../Table/Table";
import AddModal from "./AddModal";
import styles from "./Jobs.module.scss";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";

const JobsProfessions = () => {
   const [search, setsearch] = useState("");
   const [professions, setprofessions] = useState([]);
   const [rows, setrows] = useState([]);
   const [currentPage, setcurrentPage] = useState(1);
   const [totalPages, settotalPages] = useState(null);
   const [loading, setloading] = useState(true);
   const [modal, setmodal] = useState(false);

   const [columns] = useState([
      { content: "Name" },
      { content: "Optionen", minWidth: "80px" }
   ]);

   const generateRows = (list) => {
      const newRows = list.map((item) => ({
         metaData: {
            id: item.ProfessionId
         },
         rowData: [
            { content: item.ProfesionName },
            {
               content: <Options id={item.ProfessionId} />
            }
         ]
      }));
      setrows(newRows);
   };

   const getProfessionWrapper = useCallback(() => {
      setloading(true);
      getProfessions(currentPage, 10, search).then((res) => {
         setcurrentPage(res.CurrentPage);
         settotalPages(res.TotalPages);
         setprofessions(res.list);
         setloading(false);
      });
   }, [currentPage, search]);

   const onPageChange = (e, page) => {
      console.log(page);
        setcurrentPage(page);
      };

   useEffect(() => {
      getProfessionWrapper();
   }, [getProfessionWrapper]);

   useEffect(() => {
      generateRows(professions);
   }, [professions]);

   const Options = ({ id }) => {
      return (
         <div className="dfc">
            <button
               className="redSvg mx-10"
               onClick={() => {
                  deleteProfession(id)
                     .then(() => {
                        getProfessionWrapper();
                     })
                     .catch(() => {});
               }}
            >
               <DeleteIcon />
            </button>
         </div>
      );
   };

   return (
      <>
         <div className={styles.container}>
            <div className="w100 dflex justifyBetween">
               <h3 className={styles.heading}> Berufe </h3>
               <Button
                  text="Beruf hinzufügen"
                  medium
                  onClick={() => setmodal(true)}
               />
            </div>
            <Input
               value={search}
               label="Suche"
               onChange={(e) => {
                  setcurrentPage(1);
                  setsearch(e.target.value);
               }}
               inputContainerClass={styles.searchInput}
            />
            {loading ? (
               <>
                  <div className={`${styles.spinnerContainer} dfc w100 `}>
                     <Spinner />
                  </div>
               </>
            ) : (
               <>
                  <Table
                     columns={columns}
                     rows={rows}
                     totalPages={totalPages}
                     currentPage={currentPage}
                     onPageChange={onPageChange}
                     onNextPage={() => setcurrentPage(currentPage + 1)}
                     onPreviousPage={() => setcurrentPage(currentPage - 1)}
                  />
               </>
            )}
         </div>
         <AddModal
            open={modal}
            onClose={() => {
               setcurrentPage(1);
               setsearch("");
               getProfessionWrapper();
               setmodal(false);
            }}
            label="Beruf"
            onAdd={(obj) => {
               addProfession(obj.name);
            }}
         />
      </>
   );
};

export default JobsProfessions;
