import api from "./api";

export const getStatistics = async () => {
   try {
      const res = await api.get("/admin/getstatistics", {});
      return res.data;
   } catch (err) {
      return err;
   }
};

export const uploadFile = async (data, userId = null) => {
   try {
      const formData = new FormData();
      formData.append('File', data.file);
      formData.append('Type', 'Upload image');
      formData.append('UserId', userId);

      const res = await api.post("/file", formData)
      return res.data;
   } catch (err) {
      return err.response;
   }
};