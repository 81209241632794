import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changeAdminPassword } from "../../api/authApi";
import {
   checkAreFieldsFilled,
   validatePassword
} from "../../utils/inputFieldValidators";
import Button from "../Layout/Button";
import Input from "../Layout/Input";
import Modal from "../Layout/Modal";
import styles from "./AdminList.module.scss";

const ChangeAdminPasswordModal = ({ id, open, onClose }) => {
   const dispatch = useDispatch();
   const [formData, setformData] = useState({
      password: "",
      password2: ""
   });

   const [errors, seterrors] = useState({
      password: "",
      password2: ""
   });

   const { password, password2 } = formData;

   const onChange = (e) => {
      if (errors[e.target.name].length > 0) {
         seterrors({ ...errors, [e.target.name]: "" });
      }
      setformData({ ...formData, [e.target.name]: e.target.value });
   };
   const onSubmit = (e) => {
      e.preventDefault();

      if (validateFields()) {
         dispatch(
            changeAdminPassword({
               id,
               password
            })
         );
      }
   };

   const validateFields = () => {
      let valid = true;
      let newErrors = { ...errors };

      const passErr = validatePassword(password);
      if (passErr) {
         newErrors = {
            ...newErrors,
            password: passErr
         };
         valid = false;
      }

      if (password2 !== password) {
         newErrors = {
            ...newErrors,
            password2: "Passwörter sind nicht gleich!",
            password: "Passwörter sind nicht gleich!"
         };
         valid = false;
      }

      const confirmPassErr = validatePassword(password2);
      if (confirmPassErr) {
         newErrors = {
            ...newErrors,
            password2: confirmPassErr
         };
         valid = false;
      }

      //check if fields are empty
      const emptyFieldsObj = checkAreFieldsFilled(formData);
      if (Object.keys(emptyFieldsObj).length > 0) {
         valid = false;
         newErrors = { ...newErrors, ...emptyFieldsObj };
      }

      seterrors(newErrors);
      return valid;
   };

   const onCloseWrapper = () => {
      setformData({
         password: "",
         password2: ""
      });

      seterrors({
         password: "",
         password2: ""
      });
      onClose();
   };

   return (
      <Modal onClose={onCloseWrapper} open={open}>
         <div className={styles.modalContainer}>
            <form className={styles.form} onSubmit={onSubmit}>
               <Input
                  name="password"
                  type="password"
                  onChange={onChange}
                  value={password}
                  label="Passwort"
                  errorMsg={errors.password}
                  required
               />
               <Input
                  name="password2"
                  type="password"
                  onChange={onChange}
                  value={password2}
                  label="Bestätige das Passwort"
                  errorMsg={errors.password2}
                  required
               />

               <Button text="Passwort ändern" medium type="submit" />
            </form>
         </div>
      </Modal>
   );
};

export default ChangeAdminPasswordModal;
