import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAlert } from "../store/alertSlice";
import api from "./api";
import setAuthToken from "./setAuthToken";
import jwt_decode from 'jwt-decode';

export const login = createAsyncThunk(
   "auth/login",
   async (data, { rejectWithValue, dispatch }) => {
      try {
         const res = await api.post("/authentication/login", data);
         var decoded = jwt_decode(res.data.Token);
            if(decoded?.Role !== 'Admin') throw  ({response: {data:{errors: [{message:"Nicht berechtigt."}]}}});
         console.log("ovo je rezultat logina: ", res);
         console.log("decoded ", decoded);
         setAuthToken(res.data.Token);
         dispatch(getUser());
         return res.data;
      } catch (err) {
         return rejectWithValue(err.response.data);
      }
   }
);

export const getUser = createAsyncThunk(
   "auth/getUser",
   async (token, { rejectWithValue }) => {
      try {
         const res = await api.get("/authentication/validatecurrenttoken", {
            // params: {
            //    token
            // }
         });
         return res.data;
      } catch (err) {
         return rejectWithValue(err.response.data);
      }
   }
);

export const changePassword = createAsyncThunk(
   "auth/changePassword",
   async (obj, { rejectWithValue, dispatch }) => {
      try {
         const res = await api.post(
            `/authentication/change-password-from-settings/${obj.id}`,
            obj.data
         );
         dispatch(setAlert("Passwort erfolgreich geändert."));
         return res.data;
      } catch (err) {
         return rejectWithValue(err.response.data);
      }
   }
);

export const registerAdmin = createAsyncThunk(
   "auth/registerAdmin",
   async (data, { rejectWithValue, dispatch }) => {
      try {
         const res = await api.post(`/authentication/registeradmin`, data);
         dispatch(setAlert("Admin erfolgreich erstellt."));

         return res.data;
      } catch (err) {
         return rejectWithValue(err.response.data);
      }
   }
);

export const getAdmins = async () => {
   try {
      const res = await api.get("/admin/getadmins");
      return res.data;
   } catch (err) {
      return err?.response;
   }
};

export const changeAdminPassword = createAsyncThunk(
   "auth/changeAdminPassword",
   async (data, { rejectWithValue, dispatch }) => {
      try {
         const res = await api.post(`/admin/changeadminpassword`, data);
         dispatch(setAlert("Passwort erfolgreich geändert."));
         return res.data;
      } catch (err) {
         return rejectWithValue(err.response.data);
      }
   }
);
