import React from "react";
import AdminList from "./AdminList";
import ChangePassword from "./ChangePassword";

const Settings = () => {
   return (
      <>
         <ChangePassword />
         <AdminList />
      </>
   );
};

export default Settings;
