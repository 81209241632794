import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAlert } from "../../store/alertSlice";
import styles from "./Alert.module.scss";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";

const Alert = () => {
   const alerts = useSelector((state) => state.alert);
   const dispatch = useDispatch();

   useEffect(() => {
      if (alerts.length > 0) {
         setTimeout(() => {
            dispatch(deleteAlert(alerts[alerts.length - 1].id));
         }, 5000);
      }
   }, [dispatch, alerts]);

   return (
      <>
         <div className={`${styles.alertsContainer}`}>
            {alerts.map((alert) => (
               <div
                  className={`${styles.alert} ${styles[alert.alertType]}`}
                  key={alert.id}
               >
                  <p> {alert.msg}</p>
                  <div
                     onClick={() => {
                        dispatch(deleteAlert(alert.id));
                     }}
                  >
                     <CloseIcon />
                  </div>
               </div>
            ))}
         </div>
      </>
   );
};

export default Alert;
