import api from "./api";
import { setAlert } from "../store/alertSlice";
import { store } from "../store/store";

export const getTags = async (companyId) => {
   try {
      const res = await api.get(`/api/jobtag/${companyId}`, {});
      return res.data;
   } catch (err) {
      return err;
   }
};

export const postTags = async (companyId, data) => {
   try {
      await api.post(`/api/jobtag/bulk-insert/${companyId}`, data);
      store.dispatch(setAlert("Neue Tags wurden erfolgreich hinzugefügt."));
      return;
   } catch (err) {
      return err.response;
   }
};
