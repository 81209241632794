import React, { useEffect, useState } from "react";
import { getAdmins } from "../../api/authApi";
import formatDate from "../../utils/formatDate";
import Button from "../Layout/Button";
import AddAdminModal from "./AddAdminModal";
import styles from "./AdminList.module.scss";
import { ReactComponent as KeyIcon } from "../../assets/images/key.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import { useSelector } from "react-redux";
import Table from "../Table/Table";
import DeletePrompt from "../DeletePrompt/DeletePrompt";
import { deleteUser } from "../../api/usersApi";
import Spinner from "../Layout/Loading/Spinner";
import ChangeAdminPasswordModal from "./ChangeAdminPasswordModal";

const AdminList = () => {
   const [addAdminModal, setaddAdminModal] = useState(false);
   const userId = useSelector((state) => state.auth.user?.userId);
   const [adminList, setadminList] = useState([]);
   const [rows, setrows] = useState([]);
   const [deleteModal, setdeleteModal] = useState(false);
   const [editAdminId, seteditAdminId] = useState(null);
   const [loading, setloading] = useState(true);
   const [changePassModal, setchangePassModal] = useState(false);

   const getAdminsWrapper = () => {
      setloading(true);
      getAdmins().then((res) => {
         setadminList(res);
         setloading(false);
      });
   };

   useEffect(() => {
      getAdminsWrapper();
   }, []);

   useEffect(() => {
      generateRows(adminList);
   }, [adminList]);

   const generateRows = (list) => {
      const newRows = list.map((item) => ({
         metaData: {
            id: item.Id
         },
         rowData: [
            { content: item.Email },
            { content: formatDate(item.UserCreatedTime) },
            { content: <Options id={item.Id} /> }
         ]
      }));
      setrows(newRows);
   };

   const columns = [
      {
         content: "Email"
      },
      {
         content: "hergestellt in",
         minWidth: "200px"
      },
      {
         content: "Optionen",
         minWidth: "100px"
      }
   ];

   const Options = ({ id }) => {
      return (
         <div className="dfc">
            {/* admin should not be able to change password of default admin (admin@admin.com) 
            and should not be able to change his own password (there is separate "Change Password" section for that functionality)  */}
            {id !== 1 && id !== parseInt(userId) && (
               <button
                  className="redSvg mx-10"
                  onClick={() => {
                     setchangePassModal(true);
                     seteditAdminId(id);
                  }}
               >
                  <KeyIcon />
               </button>
            )}
            {id !== 1 && (
               <button
                  className="redSvg mx-10"
                  onClick={() => {
                     setdeleteModal(true);
                     seteditAdminId(id);
                  }}
               >
                  <DeleteIcon />
               </button>
            )}
         </div>
      );
   };

   return (
      <div className={styles.container}>
         <div className={styles.header}>
            <h3>Admin-Liste</h3>
            <Button
               text={"Admin hinzufügen"}
               onClick={() => setaddAdminModal(true)}
               medium
            />
         </div>

         {loading ? (
            <>
               <div className="w100">
                  <Spinner />
               </div>
            </>
         ) : (
            <>
               <Table columns={columns} rows={rows} pagination={false} />
            </>
         )}
         <AddAdminModal
            open={addAdminModal}
            onClose={() => {
               setaddAdminModal(false);
               getAdminsWrapper();
            }}
         />
         <DeletePrompt
            open={deleteModal}
            onClose={() => {
               setdeleteModal(false);
               seteditAdminId(null);
            }}
            onCancel={() => {
               setdeleteModal(false);
               seteditAdminId(null);
            }}
            onConfirm={() => {
               deleteUser(editAdminId).then(() => {
                  getAdminsWrapper();
                  setdeleteModal(false);
                  seteditAdminId(null);
               });
            }}
            text={"Möchten Sie dieses Administratorkonto wirklich löschen?"}
         />
         <ChangeAdminPasswordModal
            id={editAdminId}
            open={changePassModal}
            onClose={() => {
               setchangePassModal(false);
               seteditAdminId(null);
            }}
         />
      </div>
   );
};

export default AdminList;
