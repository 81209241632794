import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as LinkIcon } from "../../assets/images/openLink.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import DeletePrompt from "../DeletePrompt/DeletePrompt";
import styles from "./Blogs.module.scss";
import Table from "../Table/Table";
import ImageAndName from "../Users/Tabs/ImageAndName";
import Spinner from "../Layout/Loading/Spinner";
import { deleteBlog, getBlogs } from "../../api/blogsApi";
import formatDate from "../../utils/formatDate";

const BlogsTable = ({ search, userId = null, showCompany = true }) => {
   const [blogs, setblogs] = useState("");
   const [currentPage, setcurrentPage] = useState(1);
   const [totalPages, settotalPages] = useState(null);
   const [loading, setloading] = useState(true);
   const [deleteModal, setdeleteModal] = useState(false);
   const [deleteBlogId, setdeleteBlogId] = useState(null);

   const [columns] = useState([
      ...(showCompany
         ? [
              {
                 content: "Firma",
                 minWidth: "250px"
              }
           ]
         : []),
      {
         content: "Title",
         minWidth: "400px"
      },
      {
         content: "hergestellt in",
         minWidth: "150px"
      },
      {
         content: "Optionen",
         minWidth: "100px"
      }
   ]);

   const getBlogsWrapper = useCallback(() => {
      setloading(true);
      getBlogs(currentPage, 10, search, userId).then((res) => {
         setcurrentPage(res.CurrentPage);
         settotalPages(res.TotalPages);
         const rows = res.list.map((item) => ({
            metaData: {
               id: item.BlogsId
            },
            rowData: [
               ...(showCompany
                  ? [
                       {
                          content: (
                             <ImageAndName
                                name={item.User.Name}
                                url={item.ProfileImage}
                             />
                          )
                       }
                    ]
                  : []),
               { content: item.Title },
               { content: formatDate(item.CreatedDate) },
               {
                  content: (
                     <Options
                        url={`${process.env.REACT_APP_APP_LINK}/blogeintrag/${item.BlogsId}`}
                        id={item.BlogsId}
                     />
                  )
               }
            ]
         }));

         setblogs(rows);
         setloading(false);
      });
   }, [currentPage, search, showCompany, userId]);

   const onPageChange = (e, page) => {
        setcurrentPage(page);
      };

   useEffect(() => {
      getBlogsWrapper();
   }, [currentPage, search, getBlogsWrapper]);

   const Options = ({ url, id }) => {
      return (
         <div className="dfc">
            <a
               href={url}
               target="_blank"
               rel="noreferrer"
               className="redSvg mx-10"
            >
               <LinkIcon />
            </a>
            <button
               className="redSvg mx-10"
               onClick={() => {
                  setdeleteModal(true);
                  setdeleteBlogId(id);
               }}
            >
               <DeleteIcon />
            </button>
         </div>
      );
   };

   return (
      <>
         {loading ? (
            <>
               <div className={`${styles.spinnerContainer} dfc `}>
                  <Spinner />
               </div>
            </>
         ) : (
            <>
               <Table
                  columns={columns}
                  rows={blogs}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                  currentPage={currentPage}
                  onNextPage={() => setcurrentPage(currentPage + 1)}
                  onPreviousPage={() => setcurrentPage(currentPage - 1)}
                  openInNewTab
               />
            </>
         )}
         <DeletePrompt
            open={deleteModal}
            onClose={() => {
               setdeleteModal(false);
               setdeleteBlogId(null);
            }}
            onCancel={() => {
               setdeleteModal(false);
               setdeleteBlogId(null);
            }}
            onConfirm={() => {
               deleteBlog(deleteBlogId).then(() => {
                  getBlogsWrapper();
                  setdeleteModal(false);
                  setdeleteBlogId(null);
               });
            }}
            
         />
      </>
   );
};

export default BlogsTable;
