import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApplicantJobs, getUserProfile } from "../../../api/usersApi";
import Spinner from "../../Layout/Loading/Spinner";
import styles from "./Profiles.module.scss";
import { ReactComponent as LinkIcon } from "../../../assets/images/openLink.svg";
import ImageAndName from "../Tabs/ImageAndName";
import Table from "../../Table/Table";
import formatDate from "../../../utils/formatDate";

const ApplicantProfile = () => {
   const params = useParams();
   const [loading, setloading] = useState(true);
   const [user, setuser] = useState(null);
   const [jobsList, setjobsList] = useState([]);
   const [rows, setrows] = useState([]);

   const [columns] = useState([
      {
         content: "Company",
         minWidth: "250px"
      },
      {
         content: "Name",
         minWidth: "250px"
      },
      {
         content: "Standorte",
         minWidth: "200px"
      },
      {
         content: "Typ",
         minWidth: "250px"
      },
      {
         content: "Created at",
         minWidth: "150px"
      },
      {
         content: "Applied at",
         minWidth: "150px"
      },
      {
         content: "Handlung",
         minWidth: "100px"
      }
   ]);

   const generateRows = useCallback(() => {
      const newRows = jobsList.map((item) => (
         {

         metaData: {
            id: item.JobAdvertisement.JobAdvertisementId
         },
         rowData: [
            {
               content: 
                 item.JobAdvertisement.Company?.CompanyName
               
            },
            { content: item.JobAdvertisement.Profession?.ProfesionName },
            { content: `${item.JobAdvertisement.JobLocation?.City}` },
            { content: (item.OfficeApplications[0]?.Employments.map(employment => employment?.EmploymentType)).join(', ') },

            {
               content: formatDate(item.JobAdvertisement.DateCreated)
            },
            { content: formatDate(item.CreatedDate) },
            {
               content: (
                  <Options
                     url={`${process.env.REACT_APP_APP_LINK}/job/${item.JobAdvertisementId}`}
                  />
               )
            }
         ]
      }));

      setrows(newRows);
   }, [jobsList]);

   useEffect(() => {
      (async () => {
         setloading(true);

         const res = await Promise.allSettled([
            getUserProfile(params.id),
            getApplicantJobs(params.id)
         ]);

         setuser(res[0].value);
         setjobsList(res[1].value);
         setloading(false);
      })();
   }, [params.id]);

   useEffect(() => {
      generateRows();
   }, [generateRows]);

   const Options = ({ url }) => {
      return (
         <div className="dfc">
            <a
               href={url}
               target="_blank"
               rel="noreferrer"
               className="redSvg mx-10"
            >
               <LinkIcon />
            </a>
         </div>
      );
   };

   return (
      <>
         {loading ? (
            <>
               <div className="dfc h100 w100">
                  <Spinner />
               </div>
            </>
         ) : (
            <>
               <div className={`${styles.container} dflex `}>
                  <div className={`${styles.personalInfo} dfc flexCol`}>
                     <img src={user.ImagePath} alt="" />
                     <h3>
                        {" "}
                        {user.Title?.TitleName} {user.FirstName} {user.LastName}{" "}
                     </h3>
                     <h6>{user.Email} </h6>
                     <h6> Benutzertyp: {user.UserType} </h6>
                  </div>
                  <div className={styles.content}>
                     <h3>Jobs</h3>

                     <Table columns={columns} rows={rows} pagination={false} />
                  </div>
               </div>
            </>
         )}
      </>
   );
};

export default ApplicantProfile;
