import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { login } from "../../api/authApi";
import Input from "../Layout/Input";
import Button from "../Layout/Button";
import styles from "./Login.module.scss";

const Login = () => {
   const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
   const [loading, setloading] = useState(false);
   const dispatch = useDispatch();

   const [formData, setformData] = useState({
      email: "",
      password: ""
   });

   const { email, password } = formData;

   const onChange = (e) => {
      setformData({ ...formData, [e.target.name]: e.target.value });
   };

   const onSubmit = (e) => {
      e.preventDefault();
      setloading(true);
      dispatch(
         login({
            userName: email,
            password
         })
      )
         .unwrap()
         .finally(() => setloading(false));
   };

   if (isAuthenticated) {
      return <Navigate to={"/"} replace />;
   }
   return (
      <>
         <div className={styles.page}>
            <form className={`${styles.form} dfc flexCol`} onSubmit={onSubmit}>
               <Input
                  name="email"
                  value={email}
                  onChange={onChange}
                  label="Email"
                  required
               />
               <Input
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  label="Passoword"
                  required
               />
               <Button type="submit" text="Login" disabled={loading} />
            </form>
         </div>
      </>
   );
};

export default Login;
