import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import LoadingScreen from "../components/Layout/Loading/LoadingScreen";

const PrivateOutlet = () => {
   const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
   const status = useSelector((state) => state.auth.status);

   return (
      <>
         {status === "idle" || status === "pending" ? (
            <LoadingScreen />
         ) : isAuthenticated ? (
            <Outlet />
         ) : (
            <Navigate to="/login" />
         )}
      </>
   );
};

export default PrivateOutlet;
