import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import styles from "./PageLayout.module.scss";

const PageLayout = () => {
   return (
      <>
         <Navbar />
         <div className={styles.mainContainer}>
            <Outlet />
         </div>
      </>
   );
};

export default PageLayout;
