import React, { useEffect } from "react";
import { getUser } from "./api/authApi";
import setAuthToken from "./api/setAuthToken";
import "./assets/styles/App.scss";
import Alert from "./components/Layout/Alert";
import Routing from "./routing/Routing";
import { store } from "./store/store";

const App = () => {
   useEffect(() => {
      // check for token in LS
      if (localStorage.token) {
         setAuthToken(localStorage.token);
      }
      store.dispatch(getUser(localStorage.token));

      // log user out from all tabs if they log out in one tab
      window.addEventListener("storage", () => {
         if (!localStorage.token) store.dispatch({ type: "/logout" });
      });
   }, []);

   return (
      <>
         <Alert />
         <Routing />
      </>
   );
};

export default App;
