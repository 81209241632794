import React from "react";
import styles from "./Modal.module.scss";
import ReactDOM from "react-dom";

const Modal = ({ open, onClose, children }) => {
   if (!open) return null;

   const content = React.cloneElement(children, {
      onClick: (e) => {
         e.stopPropagation();
      }
   });

   return (
      <>
         {ReactDOM.createPortal(
            <div
               className={`${styles.modal} dfc`}
               onClick={() => {
                  onClose();
               }}
            >
               {content}
            </div>,
            document.body
         )}
      </>
   );
};

export default Modal;
