import { setAlert } from "../store/alertSlice";
import { store } from "../store/store";
import api from "./api";

export const getUsers = async (type, pageNumber, pageSize, search) => {
   try {
      const res = await api.get(`/user/paging`, {
         params: {
            PageNumber: pageNumber,
            PageSize: pageSize,
            UserType: type,
            Filter: search
         }
      });
      return res.data;
   } catch (err) {
      return err;
   }
};

export const getApplicantJobs = async (id) => {
   try {
      const res = await api.get("/JobApplicant", {
         params: {
            ApplicantId: id,
            IncludeUser: true,
            IncludeJobAdv: true
         }
      });
      return res.data;
   } catch (err) {
      return err;
   }
};

export const deleteUser = async (id) => {
   try {
      await api.delete(`/user/${id}`);
      store.dispatch(setAlert("Benutzer erfolgreich gelöscht."));
      return;
   } catch (err) {
      return err;
   }
};

export const getUserProfile = async (id) => {
   try {
      const res = await api.get(`/user/${id}`);
      return res.data;
   } catch (err) {
      return err;
   }
};

export const addToPartner = async (companyId, partnerId) => {
   try {
      console.log(companyId, partnerId)
     const res = await api.put(`/company-partner-update`, {
      CompanyId: companyId,
      PartnerId: partnerId
     });
     console.log(res)
      store.dispatch(setAlert("Das Unternehmen wurde zu Partnern hinzugefügt."));
      return;
   } catch (err) {
      return err;
   }
};