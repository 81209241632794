import React, { useCallback, useEffect, useState } from "react";
import Statistics from "../Statistics/Statistics";
import styles from "../Jobs/Jobs.module.scss";
import Button from "../Layout/Button";
import AddModal from "./AddModal";
import {addPartner, getPartner, deletePartner } from "../../api/partnerApi";
import { uploadFile } from "../../api/helperApi";
import Spinner from "../Layout/Loading/Spinner";
import Table from "../Table/Table";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import { useSelector } from "react-redux";

const Jobs = () => {
   const [modal, setmodal] = useState(false);
   const [currentPage, setcurrentPage] = useState(1);
   const [totalPages, settotalPages] = useState(null);
   const [loading, setloading] = useState(true);
   const [partners, setPartners] = useState([]);
   const [rows, setrows] = useState([]);
   const userId = useSelector((state) => state.auth.user?.userId);

   const [columns] = useState([
    { content: "Name" },
    { content: "Art der Einrichtung" },
    { content: "Adresse" },
    { content: "Optionen", minWidth: "80px" }
 ]);

 const generateRows = (list) => {
    const newRows = list.map((item) => ({
       metaData: {
          id: item.PartnerId
       },
       rowData: [
          { content: item.PartnerName },
          { content: item.EstablishmentType },
          { content: item.Address },
          {
             content: <Options id={item.PartnerId} />
          }
       ]
    }));
    setrows(newRows);
 };

 const getPartnersWrapper = useCallback(() => {
    setloading(true);
    getPartner().then((res) => {
       setcurrentPage(1);
       settotalPages(1);
       setPartners(res);
       setloading(false);
    });
 }, [currentPage]);
 const onPageChange = (e, page) => {
   console.log(page);
     setcurrentPage(page);
   };

 useEffect(() => {
    getPartnersWrapper();
 }, [getPartnersWrapper]);

 useEffect(() => {
    generateRows(partners);
 }, [partners]);

 const Options = ({ id }) => {
    return (
       <div className="dfc">
          <button
             className="redSvg mx-10"
             onClick={() => {
                deletePartner(id)
                   .then(() => {
                      getPartnersWrapper();
                   })
                   .catch(() => {});
             }}
          >
             <DeleteIcon />
          </button>
       </div>
    );
 };

   return (
      <>
         <Statistics />
         <div className={styles.container}>
         <div className="w100 dflex justifyBetween">
            <h3 className={styles.heading}> Partner & Kooperationen </h3>
            <Button
                  text="Partner hinzufügen"
                  medium
                  onClick={() => setmodal(true)}
               />
               </div>
            {/* <Input
               value={search}
               label="Suche"
               onChange={(e) => setsearch(e.target.value)}
               inputContainerClass={styles.searchInput}
            /> */}
             {loading ? (
               <>
                  <div className={`${styles.spinnerContainer} dfc w100 `}>
                     <Spinner />
                  </div>
               </>
            ) : (
               <>
                  <Table
                     columns={columns}
                     rows={rows}
                     totalPages={totalPages}
                     currentPage={currentPage}
                     onPageChange={onPageChange}
                     onNextPage={() => setcurrentPage(currentPage + 1)}
                     onPreviousPage={() => setcurrentPage(currentPage - 1)}
                  />
               </>
            )}
         </div>

         <AddModal
            open={modal}
            onClose={() => {
               setmodal(false);
            }}
            onAdd={(obj, image) => {
                setmodal(false)
                uploadFile(image, userId).then(res => {
                   addPartner({...obj, pictureId: res.FileId}).then(() => {
                    getPartnersWrapper();
                 });
                })
            }}
         />
      </>
   );
};

export default Jobs;
