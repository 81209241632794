import React, { useEffect, useState } from "react";
import { getStatistics } from "../../api/helperApi";
import styles from "./Statistics.module.scss";

const Statistics = () => {
   const [statistics, setstatistics] = useState({
      Applicants: 0,
      Companies: 0,
      Organisations: 0,
      Jobs: 0,
      Blogs: 0
   });

   const { Applicants, Companies, Organisations, Jobs, Blogs } = statistics;

   useEffect(() => {
      getStatistics().then((res) => setstatistics(res));
   }, []);

   return (
      <>
         <div className={styles.container}>
            <p>
               Bewerber: <span> {Applicants} </span>
            </p>
            <p>
               Firmen: <span>{Companies} </span>
            </p>
            {/* <p>
               Organisationen: <span>{Organisations} </span>
            </p> */}
            <p>
               Jobs: <span> {Jobs} </span>
            </p>
            <p>
               Beiträge: <span>{Blogs} </span>
            </p>
         </div>
      </>
   );
};

export default Statistics;
