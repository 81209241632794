import React from "react";
import styles from "./Button.module.scss";

const Button = ({
   type = "button",
   disabled,
   text,
   fluid,
   onClick = () => {},
   medium,
   inversed
}) => {
   return (
      <>
         <button
            type={type}
            disabled={disabled}
            onClick={onClick}
            className={`${styles.btn}  ${fluid && "w100"} ${
               medium && styles.medium
            }  ${inversed && styles.inversed} `}
         >
            {" "}
            {text}{" "}
         </button>
      </>
   );
};

export default Button;
