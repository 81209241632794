import React from "react";
import styles from "./Loading.module.scss";
import Spinner from "./Spinner";

const Loading = () => {
   return (
      <>
         <div className={styles.container}>
            <Spinner />
         </div>
      </>
   );
};

export default Loading;
