import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserProfile } from "../../../api/usersApi";
import BlogsTable from "../../Blogs/BlogsTable";
import JobsTable from "../../Jobs/JobsTable";
import Spinner from "../../Layout/Loading/Spinner";
import styles from "./Profiles.module.scss";
import correctLink from "../../../utils/correctLink";
import InfoIcon from "../../../assets/images/info__icon.svg";
import { Fade, IconButton, Tooltip } from "@mui/material";
import TagInputAutocomplete from "./AutocompleteInput";

const CompanyProfile = () => {
   const params = useParams();
   const [loading, setloading] = useState(true);
   const [user, setuser] = useState(null);

   let infoText =
      "Durch hinzufügen von verwandten Begriffen (Synonymen) bzw. Abkürzungen lässt sich das betreffende Unternehmen in der Suchleiste finden, obwohl nicht der originale Firmenname eingegeben wird.";

   useEffect(() => {
      (async () => {
         setloading(true);
         const res = await getUserProfile(params.id);
         setuser(res);
         setloading(false);
      })();
   }, [params.id]);

   return (
      <>
         {loading ? (
            <>
               <div className="dfc h100 w100">
                  <Spinner />
               </div>
            </>
         ) : (
            <>
               <div className={`${styles.container} dflex `}>
                  <div className={`${styles.personalInfo} dfc flexCol`}>
                     <img src={user.ImagePath} alt="" />
                     <h3>{user.Name}</h3>
                     {user?.LocationAddress && (
                        <h6> {user.LocationAddress} </h6>
                     )}
                     {user?.Website && (
                        <a
                           href={correctLink(user.Website)}
                           target="_blank"
                           rel="noreferrer"
                        >
                           <h6> {user.Website} </h6>
                        </a>
                     )}

                     {user?.Email && (
                        <h6>
                           {" "}
                           Email:{" "}
                           <a href={`mailto:${user.Email} `}> {user.Email} </a>
                        </h6>
                     )}

                     {user?.Phone && (
                        <h6>
                           Phone:
                           <a href={`tel:${user.Phone}`}> {user.Phone}</a>
                        </h6>
                     )}

                     <p> Benutzertyp: {user.UserType} </p>
                  </div>
                  <div className={styles.content}>
                     <div className={`${styles.iconContainer}`}>
                        <h3>Keywords</h3>
                        <Tooltip
                           className={`${styles.textColor}`}
                           title={infoText}
                           placement="top"
                           enterTouchDelay={1400}
                           TransitionComponent={Fade}
                           TransitionProps={{ timeout: 600 }}
                        >
                           <IconButton className={`${styles.iconButton}`}>
                              <img
                                 className={`${styles.infoIcon}`}
                                 src={InfoIcon}
                              />
                           </IconButton>
                        </Tooltip>
                     </div>
                     <TagInputAutocomplete companyId = {params.id} />

                     <h3>Jobs</h3>

                     <JobsTable userId={params.id} showCompany={false} />
                     <h3>Beiträge</h3>

                     <BlogsTable userId={params.id} showCompany={false} />
                  </div>
               </div>
            </>
         )}
      </>
   );
};

export default CompanyProfile;
