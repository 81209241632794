import React, { useState } from "react";
import styles from "./Users.module.scss";
import Companies from "./Tabs/Companies";
import Organisations from "./Tabs/Organisations";
import Applicants from "./Tabs/Applicants";
import Statistics from "../Statistics/Statistics";

const Users = () => {
   const [activeTab, setactiveTab] = useState("applicants");

   const renderTab = () => {
      switch (activeTab) {
         case "companies":
            return <Companies />;
         case "organisations":
            return <Organisations />;

         default:
            return <Applicants />;
      }
   };

   return (
      <>
         <Statistics />

         <div className={styles.container}>
            <div className={styles.btnsContainer}>
               <button
                  className={`${styles.btn} ${
                     activeTab === "applicants" && styles.activeBtn
                  } `}
                  onClick={() => setactiveTab("applicants")}
               >
                  Bewerber
               </button>
               <button
                  className={`${styles.btn} ${
                     activeTab === "companies" && styles.activeBtn
                  } `}
                  onClick={() => setactiveTab("companies")}
               >
                  Firmen{" "}
               </button>
               {/* <button
                  className={`${styles.btn} ${
                     activeTab === "organisations" && styles.activeBtn
                  } `}
                  onClick={() => setactiveTab("organisations")}
               >
                  Organisationen
               </button> */}
            </div>
            {renderTab()}
         </div>
      </>
   );
};

export default Users;
