import { setAlert } from "../store/alertSlice";
import { store } from "../store/store";
import api from "./api";

export const getBlogs = async (PageNumber, PageSize, search, UserId = null) => {
   try {
      const res = await api.get("/Blogs/Paging", {
         params: {
            PageNumber,
            PageSize,
            Title: search,
            IncludePicture: true,
            UserId
         }
      });

      return res.data;
   } catch (err) {
      return err;
   }
};

export const deleteBlog = async (id) => {
   try {
      await api.delete(`/blogs/${id}`);
      store.dispatch(setAlert("Blog erfolgreich gelöscht."));
      return;
   } catch (err) {
      return err;
   }
};
