import { setAlert } from "../store/alertSlice";
import { store } from "../store/store";
import api from "./api";

export const getJobs = async (PageNumber, PageSize, search, userId = null) => {
   try {
      const res = await api.get("/JobAdvertisement/Paging", {
         params: {
            PageNumber,
            PageSize,
            VolunteeringOnly: false,
            JobTitle: search,
            UserId: userId
         }
      });

      return res.data;
   } catch (err) {
      return err;
   }
};
export const changeJobStatus = async (id, status) => {
   try {
      await api.patch(`/JobAdvertisement/${id}`, [
         { path: "/isActive", op: "replace", value: `${status}` }
      ]);
      store.dispatch(setAlert("Jobstatus wurde erfolgreich aktualisiert."));
      return id;
   } catch (err) {
      return err;
   }
};

export const deleteJob = async (id) => {
   try {
      await api.delete(`/JobAdvertisement/${id}`);
      store.dispatch(setAlert("Job wurde erfolgreich gelöscht."));
      return;
   } catch (err) {
      return err;
   }
};

export const getProfessions = async (PageNumber, PageSize, ProfesionName) => {
   try {
      const res = await api.get("/profession/paging", {
         params: { PageNumber, PageSize, ProfesionName }
      });
      return res.data;
   } catch (err) {
      return err.response;
   }
};

export const getBranches = async (isJobs = true) => {
   try {
      const res = await api.get("/branch", { params: { isJobs } });
      return res.data;
   } catch (err) {
      return err.response;
   }
};

export const addProfession = async (name) => {
   try {
      await api.post("/admin/profession", { profesionName: name });
      store.dispatch(setAlert("Neuer Beruf erfolgreich hinzugefügt."));
      return;
   } catch (err) {
      return err.response;
   }
};

export const deleteProfession = async (id) => {
   try {
      await api.delete(`/admin/profession/${id}`);
      store.dispatch(setAlert("Beruf erfolgreich gelöscht."));
      return;
   } catch (err) {
      return Promise.reject(err.response);
   }
};

export const addBranch = async (data) => {
   try {
      // on backend/database logic for isVolunteering (isEhrenamt) is inverted
      // isEhrenamt: true is actually not volunteering, while isEhrenamt: false is actually volunteering
      // beacause of that isEhrenamt is sent opposite of what user entered
      // ! if you want to change this logic, make sure that it is also changed on backend!!!
      await api.post("/admin/branch", {
         branchType: data.branchType,
         isEhrenamt: !data.isEhrenamt
      });
      store.dispatch(setAlert("Neuer Branche erfolgreich hinzugefügt."));
      return;
   } catch (err) {
      return err.response;
   }
};

export const deleteBranch = async (id) => {
   try {
      await api.delete(`/admin/branch/${id}`);
      store.dispatch(setAlert("Branche erfolgreich gelöscht."));
      return;
   } catch (err) {
      return Promise.reject(err.response);
   }
};
