import React, { useState } from "react";
import Button from "../Layout/Button";
import Input from "../Layout/Input";
import Modal from "../Layout/Modal";
import Toggle from "../Layout/Toggle";
import styles from "./Jobs.module.scss";

const AddModal = ({ open, onClose, label, onAdd, isBranch }) => {
   const [name, setname] = useState("");
   const [volunteering, setvolunteering] = useState(false);
   const [errorMsg, seterrorMsg] = useState("");

   const onSubmit = (e) => {
      e.preventDefault();

      if (name.length === 0) {
         seterrorMsg("Dieses Feld kann nicht leer sein.");
      } else {
         onAdd({ name, isEhrenamt: volunteering });
         setname("");
      }
   };

   return (
      <>
         <Modal
            open={open}
            onClose={() => {
               setname("");
               setvolunteering(false);
               seterrorMsg("");
               onClose();
            }}
         >
            <div className={`${styles.modalContainer}`}>
               <form className="w100 dfc flexCol" onSubmit={onSubmit}>
                  <Input
                     value={name}
                     label={label}
                     onChange={(e) => {
                        seterrorMsg("");
                        setname(e.target.value);
                     }}
                     inputContainerClass={styles.searchInput}
                     errorMsg={errorMsg}
                  />
                  {isBranch && (
                     <div className={styles.toggleCont}>
                        <span> Ehrenamt: </span>{" "}
                        <Toggle
                           value={volunteering}
                           onChange={() => {
                              setvolunteering(!volunteering);
                           }}
                        />{" "}
                        <span>{volunteering ? "an" : "aus"}</span>
                     </div>
                  )}

                  <Button type="submit" text="Hinzufügen" medium />
               </form>
            </div>
         </Modal>
      </>
   );
};

export default AddModal;
