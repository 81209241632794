import { createSlice } from "@reduxjs/toolkit";
// import { setAlert } from "./alertSlice";

const initialState = {
   isAuthenticated: false,
   token: localStorage.getItem("token") || null,
   user: null,
   status: "idle"
};

const authSlice = createSlice({
   name: "auth",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase("auth/login/fulfilled", (state, { payload }) => {
         state.isAuthenticated = true;
         state.token = payload.Token;
      });

      builder.addCase("auth/getUser/fulfilled", (state, action) => {
         return {
            ...state,
            isAuthenticated: true,
            user: action.payload,
            status: "success"
         };
      });

      builder.addMatcher(
         (action) =>
            // (action.type.startsWith("auth/") &&
            //    action.type.endsWith("/rejected")) ||
            action.type === "/logout" ||
            action.type === "auth/getUser/rejected",
         (state) => {
            localStorage.removeItem("token");
            return {
               ...state,
               isAuthenticated: null,
               user: null,
               status: "error",
               token: null
            };
         }
      );
   }
});

//export const {} = authSlice.actions;

export default authSlice.reducer;
