import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as LinkIcon } from "../../assets/images/openLink.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import DeletePrompt from "../DeletePrompt/DeletePrompt";
import Table from "../Table/Table";
import ImageAndName from "../Users/Tabs/ImageAndName";
import styles from "./Jobs.module.scss";
import Spinner from "../Layout/Loading/Spinner";
import Toggle from "../Layout/Toggle";
import { changeJobStatus, deleteJob, getJobs } from "../../api/jobsApi";
import formatDate from "../../utils/formatDate";

const JobsTable = ({ search = "", userId, showCompany = true }) => {
   const [jobs, setjobs] = useState([]);
   const [rows, setrows] = useState([]);
   const [currentPage, setcurrentPage] = useState(1);
   const [totalPages, settotalPages] = useState(null);
   const [loading, setloading] = useState(true);
   const [deleteModal, setdeleteModal] = useState(false);
   const [deleteJobId, setdeleteJobId] = useState(null);

   const [columns] = useState([
      ...(showCompany
         ? [
              {
                 content: "Firma",
                 minWidth: "250px"
              }
           ]
         : []),
      {
         content: "Name",
         minWidth: "250px"
      },
      {
         content: "Standorte",
         minWidth: "200px"
      },
      {
         content: "Typ",
         minWidth: "250px"
      },
      {
         content: "Matching",
         minWidth: "100px"
      },
      {
         content: "Status",
         minWidth: "100px"
      },
      {
         content: "hergestellt in",
         minWidth: "150px"
      },
      {
         content: "Optionen",
         minWidth: "100px"
      }
   ]);

   const generateRows = useCallback(
      (jobsList) => {
         const newRows = jobsList.map((item) => ({
            metaData: {
               id: item.JobAdvertisementId
            },
            rowData: [
               ...(showCompany
                  ? [
                       {
                          content: (
                             <ImageAndName
                                name={item.ApplicationUser.Name}
                                url={item.UserPhotoUrl}
                             />
                          )
                       }
                    ]
                  : []),
               { content: item.Profession.ProfesionName },
               { content: `${item.JobLocation.City}` },
               { content: item.EmploymentText ?? item.Employments.map(employment => employment.EmploymentType).join(', ') },
               { content: item.MatchingId ? "Ja" : "Nein" },
               {
                  content: (
                     <Status
                        isActive={item.isActive}
                        id={item.JobAdvertisementId}
                     />
                  )
               },
               { content: formatDate(item.DateCreated) },
               {
                  content: (
                     <Options
                        url={`${process.env.REACT_APP_APP_LINK}/job/${item.JobAdvertisementId}`}
                        id={item.JobAdvertisementId}
                     />
                  )
               }
            ]
         }));

         setrows(newRows);
      },
      [showCompany, jobs]
   );

   const onPageChange = (e, page) => {
      console.log(page);
        setcurrentPage(page);
      };

   const getJobsWrapper = useCallback(() => {
      setloading(true);
      getJobs(currentPage, 10, search, userId).then((res) => {
         setcurrentPage(res.CurrentPage);
         settotalPages(res.TotalPages);
         setjobs(res.list);
         setloading(false);
      });
   }, [currentPage, search, userId]);

   useEffect(() => {
      getJobsWrapper();
   }, [getJobsWrapper]);

   useEffect(() => {
      generateRows(jobs);
   }, [jobs, generateRows]);

   const Status = ({ isActive, id }) => {
      return (
         <div className="dflex alignCenter">
            <Toggle
               value={isActive}
               onChange={() => {
                  changeJobStatus(id, !isActive).then((id) => {
                     const newList = jobs.map((item) => {
                        if (item.JobAdvertisementId === id) {
                           return { ...item, isActive: !isActive };
                        } else {
                           return { ...item };
                        }
                     });
                     setjobs(newList);
                  });
               }}
            />
         </div>
      );
   };

   const Options = ({ url, id }) => {
      return (
         <div className="dfc">
            <a
               href={url}
               target="_blank"
               rel="noreferrer"
               className="redSvg mx-10"
            >
               <LinkIcon />
            </a>
            <button
               className="redSvg mx-10"
               onClick={() => {
                  setdeleteModal(true);
                  setdeleteJobId(id);
               }}
            >
               <DeleteIcon />
            </button>
         </div>
      );
   };

   return (
      <>
         {loading ? (
            <>
               <div className={`${styles.spinnerContainer} dfc w100 `}>
                  <Spinner />
               </div>
            </>
         ) : (
            <>
               <Table
                  columns={columns}
                  rows={rows}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                  onNextPage={() => setcurrentPage(currentPage + 1)}
                  onPreviousPage={() => setcurrentPage(currentPage - 1)}
               />
            </>
         )}
         <DeletePrompt
            open={deleteModal}
            onClose={() => {
               setdeleteModal(false);
               setdeleteJobId(null);
            }}
            onCancel={() => {
               setdeleteModal(false);
               setdeleteJobId(null);
            }}
            onConfirm={() => {
               deleteJob(deleteJobId).then(() => {
                  getJobsWrapper();
                  setdeleteModal(false);
                  setdeleteJobId(null);
               });
            }}
          
         />
      </>
   );
};

export default JobsTable;
