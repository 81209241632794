import React from "react";
import Modal from "../Layout/Modal";
import Button from "../Layout/Button";
import styles from "./DeletePrompt.module.scss";

const DeletePrompt = ({
   open,
   onClose,
   heading,
   text,
   onConfirm,
   onCancel
}) => {
   return (
      <>
         <Modal open={open} onClose={onClose}>
            <div className={styles.container}>
               <p> Bist du Dir sicher, dass du alle Daten löschen möchtest? </p>
               <div className={"dfc"}>
                  <Button type="button" text="No" onClick={onCancel} medium />
                  <Button
                     type="Nein"
                     text="Ja"
                     onClick={onConfirm}
                     medium
                     inversed
                  />
               </div>
            </div>
         </Modal>
      </>
   );
};

export default DeletePrompt;
