import React, { useEffect } from "react";
import styles from "./Table.module.scss";
import { Pagination } from "@mui/material";


const Table = ({
   columns,
   rows,
   currentPage,
   totalPages,
   onNextPage,
   onPreviousPage,
   onPageChange,
   pagination = true
}) => {
   // const [currentPage_, setcurrentPage] = useState(currentPage);
   // const onPageChange = (e, page) => {
   //  console.log(page);
   //    setcurrentPage(page);
   //  };

   return (
      <>
         <div className={styles.container}>
            {rows.length > 0 ? (
               <>
                  <table className={styles.table}>
                     <colgroup>
                        {columns.map((col, index) => (
                           <col
                              span={1}
                              key={index}
                              style={{
                                 minWidth: col.minWidth || "200px",
                                 width: "100%"
                              }}
                           />
                        ))}
                     </colgroup>
                     <thead>
                        <tr>
                           {columns.map((col, index) => (
                              <th key={index}>{col.content} </th>
                           ))}
                        </tr>
                     </thead>
                     <tbody>
                        {rows.map((row) => (
                           <tr key={row.metaData.id}>
                              {row.rowData.map((item, index) => (
                                 <td key={index}>{item.content}</td>
                              ))}
                           </tr>
                        ))}
                     </tbody>
                  </table>
                  {/* {pagination && (
                     <div className={styles.pagination}>
                        <button
                           disabled={currentPage <= 1}
                           onClick={onPreviousPage}
                        >
                           &#60;
                        </button>
                        <p>{currentPage}</p>
                        <button
                           disabled={currentPage >= totalPages}
                           onClick={onNextPage}
                        >
                           &#62;
                        </button>
                     </div>
                  )} */}

                  {pagination && (
                     <Pagination
                        className={styles.pagination}
                        count={totalPages}
                        page={currentPage}
                        onChange={onPageChange}
                        shape="rounded"
                        showFirstButton
                        showLastButton
                     />
                  )}
               </>
            ) : (
               <>
                  <p className="w100"> keine Daten verfügbar </p>
               </>
            )}
         </div>
      </>
   );
};

export default Table;
