import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import styles from "./Profiles.module.scss";
import Button from "../../Layout/Button";
import { setAlert } from "../../../store/alertSlice";
import { store } from "../../../store/store";
import { getTags, postTags } from "../../../api/tagsApi";

const TagInputAutocomplete = ({ companyId }) => {
   const [tagArray, setTagArray] = useState([]);

   const handleInputChange = (e, value) => {
      if (tagArray.length < 10 && value != " ") setTagArray(value);
   };

   const handleInputKeyDown = (e) => {
      if ((e.key === "Enter") && tagArray.length > 9) {
         store.dispatch(setAlert("10 Tags sind das Maximum"));
      }
      if ((e.key === "Enter") && tagArray.length < 10) {
         addTagToArray();
         e.preventDefault();
      }
   };

   const handleChipDelete = (deletedChipIndex) => {
      const updatedTagArray = tagArray.filter(
         (_, index) => index !== deletedChipIndex
      );
      setTagArray(updatedTagArray);
   };

   const addTagToArray = () => {
      if (
         tagArray[tagArray.length - 1] &&
         tagArray[tagArray.length - 1].trim() !== "" &&
         tagArray.length < 10
      ) {
         setTagArray((prevArray) => [...prevArray, ""]);
      }
   };

   const submitTags = () => {
      postTags(companyId, tagArray);
   };

   useEffect(() => {
      getTags(companyId)
         .then((res) => {
            const tagArray = res.map((tag) => tag.Name);
            setTagArray(tagArray);
         })
         .catch((error) => {
            console.error("Error while getting tags: ", error);
         });
   }, []);

   return (
      <div className={`${styles.marginBottom}`}>
         <Autocomplete
            className={`${styles.marginInput}`}
            multiple
            id="tags-filled"
            options={[]}
            freeSolo
            value={tagArray}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            renderTags={(value, getTagProps) =>
               value.map((option, index) =>
                   option ? (
                       <Chip
                           key={index}
                           sx={{
                               "& .MuiChip-deleteIcon": {
                                   color: "#e83741"
                               }
                           }}
                           color="default"
                           style={{
                               margin: "4px",
                               color: "#e83741",
                               borderColor: "#e83741"
                           }}
                           variant="outlined"
                           label={option}
                           {...getTagProps({ index })}
                           onDelete={() => handleChipDelete(index)}
                       />
                   ) : null
               )
           }
           
            renderInput={(params) => (
               <TextField
                  placeholder={
                     tagArray.length == 0 ? "Füge hier die Begriffe ein" : ""
                  }
                  sx={{
                     "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                        borderColor: "#e83741 !important"
                     }
                  }}
                  {...params}
               />
            )}
         />
         <Button text="Save tags" medium onClick={submitTags} />
      </div>
   );
};

export default TagInputAutocomplete;
