import React from "react";
import styles from "./Input.module.scss";

const Select = ({
   value,
   onChange,
   id = null,
   name,
   inputClass = "",
   inputContainerClass = "",
   labelClass = "",
   label = null,
   errorMsg = null,
   required = false,
   icon = null,
   dateMaxValue = null,
   options=[],
   ...rest
}) => {
   return (
      <>
         <div className={`${styles.inputContainer}  ${inputContainerClass}`}>
            <div className={`${styles.fieldContainer}`}>
               {icon && <div className={`${styles.icon} dfc`}>{icon}</div>}
               <select
               className={`${styles.input} ${inputClass} ${
                errorMsg && styles.errorInput
             } `}
             onChange={onChange}
             value={value}
             name={name}
             id={id}
             placeholder=" "
             {...(dateMaxValue && {
                max: dateMaxValue
             })}
             {...rest}
               >
                <option value={null}>Keiner</option>
                {options.map(op =>  <option key={op.id} value={op.id}>{op.name}</option>)}

               </select>
               {label && (
                  <>
                     <label
                        className={`${styles.label} ${labelClass}  ${
                           errorMsg && styles.labelErr
                        }`}
                        style={
                           {
                              // color: "#d51527"
                           }
                        }
                     >
                        {label}{" "}
                        {required && <span id={styles.required}>*</span>}
                     </label>
                  </>
               )}
            </div>
            {errorMsg && <p className={styles.errMsg}> {errorMsg} </p>}
         </div>
      </>
   );
};

export default Select;
