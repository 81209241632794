import React, { useState } from "react";
import Input from "../Layout/Input";
import Statistics from "../Statistics/Statistics";
import styles from "./Jobs.module.scss";
import JobsBranches from "./JobsBranches";
import JobsProfessions from "./JobsProfessions";
import JobsTable from "./JobsTable";

const Jobs = () => {
   const [search, setsearch] = useState("");

   return (
      <>
         <Statistics />
         <div className={styles.container}>
            <h3 className={styles.heading}> Jobliste </h3>
            <Input
               value={search}
               label="Suche"
               onChange={(e) => setsearch(e.target.value)}
               inputContainerClass={styles.searchInput}
            />
            <JobsTable search={search} />
         </div>
         <JobsProfessions />
         <JobsBranches />
      </>
   );
};

export default Jobs;
