/**
 * Function to check whether fields are filled (when they are required)
 * Returns object containing error messages for fields that are empty *
 *
 * @param {object} formDataObj form data object containing form fields values
 */
export const checkAreFieldsFilled = (formDataObj) => {
   let errObj = {};

   for (const property in formDataObj) {
      if (formDataObj[property].trim() === "") {
         errObj = {
            ...errObj,
            [property]: "Dieses Feld wird benötigt."
         };
      }
   }

   return errObj;
};

export const validatePassword = (pass) => {
   let errMsg = "";

   // if (!pass.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])/)) {
   //    errMsg =
   //       "message";
   // }

   if (pass.length < 8) {
      errMsg = "Passwort muss mindestens 8 Zeichen lang sein.";
   }

   return errMsg;
};

export const checkIsEmail = (email) => {
   return email
      .toLowerCase()
      .match(
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};
