import axios from "axios";
import { setAlert } from "../store/alertSlice";
import { store } from "../store/store";

const api = axios.create({
   baseURL: `${process.env.REACT_APP_API_URL}`,
   headers: {
      "Content-Type": "application/json"
   }
});

api.interceptors.response.use(
   (response) => response,
   (error) => {
      if (
         error.response.config.url !== "/authentication/validatecurrenttoken"
      ) {
         error.response?.data?.ERROR.forEach((err) => {
            store.dispatch(setAlert(err, "error"));
         });
      }

      return Promise.reject(error);
   }
);

export default api;
