import { setAlert } from "../store/alertSlice";
import { store } from "../store/store";
import api from "./api";

export const deletePartner = async (id) => {
   try {
      await api.delete(`/partner/${id}`);
      store.dispatch(setAlert("Partner wurde erfolgreich gelöscht."));
      return;
   } catch (err) {
      return err;
   }
};

export const getPartner = async () => {
   try {
      const res = await api.get("/partner");
      return res.data;
   } catch (err) {
      return err.response;
   }
};

export const addPartner = async (data) => {
   try {
      await api.post("/partner", data);
      store.dispatch(setAlert("Neuer Partner erfolgreich hinzugefügt."));
      return;
   } catch (err) {
      return err.response;
   }
};


