import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = [];

const alertSlice = createSlice({
   name: "auth",
   initialState,
   reducers: {
      addAlert(state = initialState, action) {
         state.push(action.payload);
      },
      deleteAlert(state, { payload }) {
         return state.filter((alert) => alert.id !== payload);
      }
   },
   extraReducers: (builder) => {
      builder.addMatcher(
         (action) =>
            action.type.endsWith("/rejected") &&
            action.type !== "auth/getUser/rejected",
         (state, { payload }) => {
            if (Array.isArray(payload.errors)) {
               payload.errors.forEach((item) => {
                  const id = uuidv4();

                  alertSlice.caseReducers.addAlert(state, {
                     payload: {
                        msg: item.message,
                        alertType: "error",
                        id
                     }
                  });
               });
            }
         }
      );
   }
});

export const { addAlert, deleteAlert } = alertSlice.actions;

export default alertSlice.reducer;

export const setAlert =
   (msg, alertType = "success") =>
   (dispatch) => {
      const id = uuidv4();
      dispatch(addAlert({ msg, alertType, id }));
   };
