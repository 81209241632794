import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useMatch, useResolvedPath } from "react-router-dom";
import Button from "../Layout/Button";
import styles from "./Navbar.module.scss";
import logo from "../../assets/images/logo.png";

const Navbar = () => {
   const dispatch = useDispatch();
   const location = useLocation();
   return (
      <>
         <nav className={`${styles.nav}  `}>
            <div className={"dfc"}>
               <img src={logo} alt="logo" width={"100px"} />
            </div>
            <div className={styles.list}>
               <CustomLink to="/users"> Benutzer </CustomLink>
               <CustomLink to="/jobs"> Jobs </CustomLink>
               <CustomLink to="/partner-und-kooperationen"> Partner & Kooperationen </CustomLink>
               <CustomLink to="/blogs"> Beiträge </CustomLink>
               <CustomLink to="/settings"> Einstellungen </CustomLink>
            </div>
            <div className={styles.logout}>
               <Button
                  text="Logout"
                  medium
                  onClick={() => {
                     dispatch({ type: "/logout" });
                  }}
               />
            </div>
         </nav>
      </>
   );
};

const CustomLink = ({ children, to, ...props }) => {
   let resolved = useResolvedPath(to);
   let match = useMatch({ path: resolved.pathname, end: true });

   return (
      <>
         <Link className={`${match && styles.activeLink}`} to={to} {...props}>
            {children}
         </Link>
      </>
   );
};

export default Navbar;
