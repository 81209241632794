import React from "react";
import styles from "./Input.module.scss";

const Input = ({
   value,
   onChange,
   id = null,
   name,
   type = "text",
   inputClass = "",
   inputContainerClass = "",
   labelClass = "",
   label = null,
   errorMsg = null,
   required = false,
   icon = null,
   dateMaxValue = null,
   ...rest
}) => {
   return (
      <>
         <div className={`${styles.inputContainer}  ${inputContainerClass}`}>
            <div className={`${styles.fieldContainer}`}>
               {icon && <div className={`${styles.icon} dfc`}>{icon}</div>}
               <input
                  className={`${styles.input} ${inputClass} ${
                     errorMsg && styles.errorInput
                  } `}
                  value={value}
                  onChange={onChange}
                  name={name}
                  type={type}
                  id={id}
                  placeholder=" "
                  {...(dateMaxValue && {
                     max: dateMaxValue
                  })}
                  {...rest}
               />
               {label && (
                  <>
                     <label
                        className={`${styles.label} ${labelClass}  ${
                           errorMsg && styles.labelErr
                        }`}
                        style={
                           {
                              // color: "#d51527"
                           }
                        }
                     >
                        {label}{" "}
                        {required && <span id={styles.required}>*</span>}
                     </label>
                  </>
               )}
            </div>
            {errorMsg && <p className={styles.errMsg}> {errorMsg} </p>}
         </div>
      </>
   );
};

export default Input;
