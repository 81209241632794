import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { registerAdmin } from "../../api/authApi";
import {
   checkAreFieldsFilled,
   checkIsEmail,
   validatePassword
} from "../../utils/inputFieldValidators";
import Button from "../Layout/Button";
import Input from "../Layout/Input";
import Modal from "../Layout/Modal";
import styles from "./AddAdminModal.module.scss";

const AddAdminModal = ({ open, onClose }) => {
   const dispatch = useDispatch();
   const [formData, setformData] = useState({
      userName: "",
      email: "",
      password: "",
      confirmPassword: ""
   });

   const [errors, seterrors] = useState({
      userName: "",
      email: "",
      password: "",
      confirmPassword: ""
   });

   const { userName, email, password, confirmPassword } = formData;

   const onChange = (e) => {
      if (errors[e.target.name].length > 0) {
         seterrors({ ...errors, [e.target.name]: "" });
      }
      setformData({ ...formData, [e.target.name]: e.target.value });
   };

   const onSubmit = (e) => {
      e.preventDefault();
      if (validateFields()) {
         dispatch(registerAdmin(formData))
            .unwrap()
            .then(() => {
               setformData({
                  userName: "",
                  email: "",
                  password: "",
                  confirmPassword: ""
               });
            });
      }
   };

   const validateFields = () => {
      let valid = true;
      let newErrObj = { ...errors };
      if (!checkIsEmail(email)) {
         newErrObj = {
            ...newErrObj,
            email: "Dies ist keine gültige E-Mail-Adresse."
         };
         valid = false;
      }

      const pass2Err = validatePassword(confirmPassword);
      if (pass2Err) {
         newErrObj = {
            ...newErrObj,
            confirmPassword: pass2Err
         };
         valid = false;
      }

      if (password !== confirmPassword) {
         newErrObj = {
            ...newErrObj,
            password: "Passwörter sind nicht gleich!",
            confirmPassword: "Passwörter sind nicht gleich!"
         };
         valid = false;
      }

      const passErr = validatePassword(password);
      if (passErr) {
         newErrObj = {
            ...newErrObj,
            password: passErr
         };
         valid = false;
      }

      //check if fields are empty
      const emptyFieldsObj = checkAreFieldsFilled(formData);
      if (Object.keys(emptyFieldsObj).length > 0) {
         valid = false;
         newErrObj = { ...newErrObj, ...emptyFieldsObj };
      }

      seterrors({ ...errors, ...newErrObj });
      return valid;
   };

   return (
      <>
         <Modal open={open} onClose={onClose}>
            <div className={styles.container}>
               <form className={styles.form} onSubmit={onSubmit}>
                  <Input
                     name="userName"
                     onChange={onChange}
                     value={userName}
                     label="Nutzername"
                     errorMsg={errors.userName}
                     required
                  />
                  <Input
                     name="email"
                     onChange={onChange}
                     value={email}
                     label="Email"
                     errorMsg={errors.email}
                     required
                  />
                  <Input
                     name="password"
                     type="password"
                     onChange={onChange}
                     value={password}
                     label="Passwort"
                     errorMsg={errors.password}
                     required
                  />
                  <Input
                     name="confirmPassword"
                     type="password"
                     onChange={onChange}
                     value={confirmPassword}
                     label="Bestätige das Passwort"
                     errorMsg={errors.confirmPassword}
                     required
                  />

                  <Button text="Hinzufügen" medium type="submit" />
               </form>
            </div>
         </Modal>
      </>
   );
};

export default AddAdminModal;
