import React, { useCallback, useEffect, useState } from "react";
import { addBranch, deleteBranch, getBranches } from "../../api/jobsApi";
import Button from "../Layout/Button";
import Input from "../Layout/Input";
import Spinner from "../Layout/Loading/Spinner";
import Toggle from "../Layout/Toggle";
import Table from "../Table/Table";
import AddModal from "./AddModal";
import styles from "./Jobs.module.scss";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";

const JobsBranches = () => {
   const [search, setsearch] = useState("");
   const [branches, setbranches] = useState([]);
   const [rows, setrows] = useState([]);
   const [currentPage, setcurrentPage] = useState(1);
   const [totalPages, settotalPages] = useState(null);
   const [loading, setloading] = useState(true);
   const [modal, setmodal] = useState(false);
   const [displayData, setdisplayData] = useState([]);
   //true - show branches for normal jobs, false show jobs for volunteering
   const [branchJobType, setbranchJobType] = useState(true);

   const [columns] = useState([
      { content: "Name" },
      { content: "Optionen", minWidth: "80px" }
   ]);

   const generateRows = useCallback(() => {
      const from = (currentPage - 1) * 10;
      const to = currentPage * 10;
      const data = displayData.slice(from, to);
      settotalPages(Math.ceil(displayData.length / 10));
      const newRows = data.map((item) => ({
         metaData: {
            id: item.BranchId
         },
         rowData: [
            { content: item.BranchType },
            {
               content: <Options id={item.BranchId} />
            }
         ]
      }));
      setrows(newRows);
   }, [currentPage, displayData]);

   const getBranchesWrapper = useCallback(() => {
      setloading(true);
      getBranches(branchJobType).then((res) => {
         setbranches(res);
         setdisplayData(res);
         setloading(false);
      });
   }, [branchJobType]);

   const onPageChange = (e, page) => {
      console.log(page);
        setcurrentPage(page);
      };

   useEffect(() => {
      getBranchesWrapper();
   }, [getBranchesWrapper]);

   useEffect(() => {
      generateRows();
   }, [generateRows]);

   const Options = ({ id }) => {
      return (
         <div className="dfc">
            <button
               className="redSvg mx-10"
               onClick={() => {
                  deleteBranch(id)
                     .then(() => getBranchesWrapper())
                     .catch(() => {});
               }}
            >
               <DeleteIcon />
            </button>
         </div>
      );
   };

   return (
      <>
         <div className={styles.container}>
            <div className="w100 dflex justifyBetween">
               <h3 className={styles.heading}> Branche </h3>
               <Button
                  text="Branche hinzufügen"
                  medium
                  onClick={() => setmodal(true)}
               />
            </div>
            <Input
               value={search}
               label="Suche"
               onChange={(e) => {
                  setcurrentPage(1);
                  setsearch(e.target.value);
                  setdisplayData(
                     branches.filter((x) =>
                        x.BranchType.toLowerCase().includes(
                           e.target.value.toLowerCase()
                        )
                     )
                  );
               }}
               inputContainerClass={styles.searchInput}
            />
            <div className={styles.toggleCont}>
               <span> Ehrenamt: </span>{" "}
               <Toggle
                  value={!branchJobType}
                  onChange={() => {
                     setbranchJobType(!branchJobType);
                  }}
               />{" "}
               <span>{!branchJobType ? "an" : "aus"}</span>
            </div>
            {loading ? (
               <>
                  <div className={`${styles.spinnerContainer} dfc w100 `}>
                     <Spinner />
                  </div>
               </>
            ) : (
               <>
                  <Table
                     columns={columns}
                     rows={rows}
                     totalPages={totalPages}
                     currentPage={currentPage}
                     onPageChange={onPageChange}
                     onNextPage={() => setcurrentPage(currentPage + 1)}
                     onPreviousPage={() => setcurrentPage(currentPage - 1)}
                  />
               </>
            )}
         </div>
         <AddModal
            open={modal}
            onClose={() => {
               setcurrentPage(1);
               setsearch("");
               getBranchesWrapper();
               setmodal(false);
            }}
            label="Branche"
            onAdd={(obj) => {
               addBranch({
                  branchType: obj.name,
                  isEhrenamt: obj.isEhrenamt
               });
            }}
            isBranch
         />
      </>
   );
};
export default JobsBranches;
