import React from "react";
import styles from "../Users.module.scss";

const ImageAndName = ({ url, name }) => {
   return (
      <>
         <div className={`${styles.imageAndName} dflex alignCenter`}>
            {url && <img src={url} alt=" " />}
            <p>{name} </p>
         </div>
      </>
   );
};

export default ImageAndName;
