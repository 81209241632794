import React, { useEffect, useState } from "react";
import { addToPartner, deleteUser, getUsers } from "../../../api/usersApi";
import Input from "../../Layout/Input";
import Spinner from "../../Layout/Loading/Spinner";
import Table from "../../Table/Table";
import styles from "../Users.module.scss";
import ImageAndName from "./ImageAndName";
import { ReactComponent as LinkIcon } from "../../../assets/images/openLink.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete.svg";
import { ReactComponent as BusinessIcon } from "../../../assets/images/business-center.svg";
import { Link } from "react-router-dom";
import formatDate from "../../../utils/formatDate";
import DeletePrompt from "../../DeletePrompt/DeletePrompt";
import PartnersModal from "./PartnersModal";

const Companies = () => {
   const [search, setsearch] = useState("");
   const [companies, setcompanies] = useState([]);
   const [currentPage, setcurrentPage] = useState(1);
   const [totalPages, settotalPages] = useState(null);
   const [loading, setloading] = useState(true);
   const [deleteModal, setdeleteModal] = useState(false);
   const [deleteCompanyId, setdeleteCompanyId] = useState(null);
   const [modal, openModal] = useState(false);
   const [partner, setPartner] = useState("");

   const [columns] = useState([
      {
         content: "Name",
         minWidth: "250px"
      },
      {
         content: "Paket",
         minWidth: "250px"
      },
      {
         content: "hergestellt in",
         minWidth: "200px"
      },
      {
         content: "Optionen",
         minWidth: "100px"
      }
   ]);

   const getUsersWrapper = () => {
      setloading(true);
      getUsers("Company", currentPage, 10, search).then((res) => {
         setcurrentPage(res.CurrentPage);
         settotalPages(res.TotalPages);
         const rows = res.list.map((item) => ({
            metaData: {
               id: item.Id,
               path: `/applicant/${item.Id}`
            },
            rowData: [
               {
                  content: <ImageAndName url={item.PhotoUrl} name={item.Name} />
               },
               { content: item.Package },
               {
                  content: formatDate(item.UserCreatedTime)
               },
               {
                  content: (
                     <Options
                        url={`/company/${item.Id}`}
                        id={item.Id}
                        partnerId={item.PartnerId}
                     />
                  )
               }
            ]
         }));

         setcompanies(rows);
         setloading(false);
      });
   };

   const onPageChange = (e, page) => {
      console.log(page);
      setcurrentPage(page);
   };

   useEffect(() => {
      getUsersWrapper();
   }, [search, currentPage]);

   const Options = ({ url, id, partnerId }) => {
      return (
         <div className="dfc">
            <button
               className="redSvg mx-10"
               onClick={() => {
                  setdeleteCompanyId(id);
                  setPartner(partnerId ?? "");
                  openModal(true);
               }}
            >
               <BusinessIcon />
            </button>

            <Link to={url} className="redSvg mx-10">
               <LinkIcon />
            </Link>
            <button
               className="redSvg mx-10"
               onClick={() => {
                  setdeleteModal(true);
                  setdeleteCompanyId(id);
               }}
            >
               <DeleteIcon />
            </button>
         </div>
      );
   };

   return (
      <>
         <Input
            value={search}
            label="Suche"
            onChange={(e) => setsearch(e.target.value)}
            inputContainerClass={styles.searchInput}
         />
         {loading ? (
            <>
               <div className={`${styles.spinnerContainer} dfc `}>
                  <Spinner />
               </div>
            </>
         ) : (
            <Table
               columns={columns}
               rows={companies}
               totalPages={totalPages}
               currentPage={currentPage}
               onPageChange={onPageChange}
               onNextPage={() => setcurrentPage(currentPage + 1)}
               onPreviousPage={() => setcurrentPage(currentPage - 1)}
            />
         )}
         <DeletePrompt
            open={deleteModal}
            onClose={() => {
               setdeleteModal(false);
               setdeleteCompanyId(null);
            }}
            onCancel={() => {
               setdeleteModal(false);
               setdeleteCompanyId(null);
            }}
            onConfirm={() => {
               deleteUser(deleteCompanyId).then((res) => {
                  getUsersWrapper();
                  setdeleteModal(false);
                  setdeleteCompanyId(null);
               });
            }}
         />
         <PartnersModal
            open={modal}
            onClose={() => openModal(false)}
            partner={partner}
            setPartner={setPartner}
            onAdd={() => {
               openModal(false);
               let p = partner;
               if (partner === "Keiner") p = null;
               else {
                  partner ? (p = parseInt(partner)) : (p = partner);
               }
               addToPartner(deleteCompanyId, p).then(() => getUsersWrapper());
            }}
         />
      </>
   );
};

export default Companies;
