import { combineReducers } from "redux";
import authSlice from "./authSlice";
import alertSlice from "./alertSlice";

const appReducer = combineReducers({
   auth: authSlice,
   alert: alertSlice
});

const rootReducer = (state, action) => {
   if (action.type === "/logout") {
      localStorage.removeItem("token");
      return appReducer(undefined, action);
   }
   return appReducer(state, action);
};

export default rootReducer;
