import React, { useState } from "react";
import Input from "../Layout/Input";
import Button from "../Layout/Button";
import styles from "./ChangePassword.module.scss";
import { changePassword } from "../../api/authApi";
import { useDispatch, useSelector } from "react-redux";
import {
   checkAreFieldsFilled,
   validatePassword
} from "../../utils/inputFieldValidators";

const ChangePassword = () => {
   const dispatch = useDispatch();
   const userId = useSelector((state) => state.auth.user.userId);
   const [formData, setformData] = useState({
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
   });

   const [errors, seterrors] = useState({
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
   });

   const { currentPassword, newPassword, confirmPassword } = formData;

   const onChange = (e) => {
      if (errors[e.target.name].length > 0) {
         seterrors({ ...errors, [e.target.name]: "" });
      }
      setformData({ ...formData, [e.target.name]: e.target.value });
   };

   const onSubmit = (e) => {
      e.preventDefault();

      if (validateFields()) {
         dispatch(changePassword({ id: userId, data: formData }))
            .unwrap()
            .then(() => {
               setformData({
                  currentPassword: "",
                  newPassword: "",
                  confirmPassword: ""
               });
            })
            .catch(() => {});
      }
   };

   const validateFields = () => {
      let valid = true;
      let newErrors = { ...errors };

      const currentPassErr = validatePassword(currentPassword);
      if (currentPassErr) {
         newErrors = {
            ...newErrors,
            currentPassword: currentPassErr
         };
         valid = false;
      }

      const confirmPassErr = validatePassword(confirmPassword);
      if (confirmPassErr) {
         newErrors = {
            ...newErrors,
            confirmPassword: confirmPassErr
         };
         valid = false;
      }

      if (newPassword !== confirmPassword) {
         newErrors = {
            ...newErrors,
            newPassword: "Passwörter sind nicht gleich!",
            confirmPassword: "Passwörter sind nicht gleich!"
         };
         valid = false;
      }

      const passErr = validatePassword(newPassword);
      if (passErr) {
         newErrors = {
            ...newErrors,
            newPassword: passErr
         };
         valid = false;
      }

      //check if fields are empty
      const emptyFieldsObj = checkAreFieldsFilled(formData);
      if (Object.keys(emptyFieldsObj).length > 0) {
         valid = false;
         newErrors = { ...newErrors, ...emptyFieldsObj };
      }

      seterrors(newErrors);
      return valid;
   };

   return (
      <>
         <div className={styles.container}>
            <h3> Passwort ändern </h3>
            <form className={styles.form} onSubmit={onSubmit}>
               <Input
                  name="currentPassword"
                  type="password"
                  onChange={onChange}
                  value={currentPassword}
                  label="Old password"
                  inputContainerClass={styles.inputContainer}
                  errorMsg={errors.currentPassword}
                  required
               />
               <Input
                  name="newPassword"
                  type="password"
                  onChange={onChange}
                  value={newPassword}
                  label="New password"
                  errorMsg={errors.newPassword}
                  inputContainerClass={styles.inputContainer}
                  required
               />
               <Input
                  name="confirmPassword"
                  type="password"
                  onChange={onChange}
                  value={confirmPassword}
                  inputContainerClass={styles.inputContainer}
                  errorMsg={errors.confirmPassword}
                  label="Confirm new password"
                  required
               />
               <Button type="submit" text="Change password" medium />
            </form>
         </div>
      </>
   );
};

export default ChangePassword;
